import {
  getControllersRequest,
  getControllerByIdRequest,
  setBleConfigRequest,
  setExternalInterfacesRequest,
  setGpioConfigRequest,
  setLockConfigRequest,
  setNetworkConfigRequest,
  setTimeRequest,
  uploadCardAccessesRequest,
  uploadUserAccessesRequest,
  createControllerRequest,
  deleteControllerByIdRequest,
  deletePermanentControllerByIdRequest,
  patchControllerByIdRequest,
  restoreControllerByIdRequest,
  getControllerHashByIdRequest,
  getControllerRequestReport
} from "@/helpers/api/controllers";
import { getAccessGroupsRequest } from "@/helpers/api/access-group";
import router from "@/router";
import { csvExport } from "@/helpers/CsvHelper";
import { wsControllerConnection } from "@/helpers/api/ws";

const state = {
  pageControllersList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  dealerControllersList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  controllerCreateForm: {
    diller: null,
    object: null,
    name: "",
    city: null,
    type: "",
    organization: "",
    addToAllGroups: false
  },
  organizationControllers: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  objectOrganizationControllersList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  controllerEditForm: {
    object: { value: null, isEdit: false },
    name: { value: "", isEdit: false },
    type: { value: "", isEdit: false }
  },
  controllerInfo: {
    results: {}
  },
  controllerSettings: {
    controllerInfoById: {},
    controllerInfoHashById: {
      app_ttl: 0,
      card_ttl: 0
    },
    bluetoothettings: {
      ble: 0
    },
    externalInterfacesSettings: {
      wiegand1: 0,
      wiegand2: 0,
      rs1: 0,
      rs2: 0
    },
    gpioConfigSettings: {
      number: 0,
      logic: 0,
      level_of_one: 0,
      signal_type: 0
    },
    lockConfigSettings: {
      lock_connection_type: 0,
      lock_door_opened_time: 0
    },
    networkSettings: {
      network_status: "On",
      network_ssid: "string",
      network_password: "string"
    },
    timeSettings: {},
    cardAccessesSettings: {
      inner_mac: "string",
      outer_mac: "string",
      is_active: true,
      name: "string",
      type: "door",
      comment: "string",
      connected: true,
      inner_receiver_enabled: true,
      hands_free: true,
      trigger_level: 0,
      reader_accept_bytes: 0,
      lock_connection_type: 0,
      lock_door_opened_time: 0,
      ble: 0,
      wiegand1: 0,
      wiegand2: 0,
      rs1: 0,
      rs2: 0,
      network_status: "On",
      network_ssid: "string",
      network_password: "string",
      object: 0,
      outer_firmware: 0,
      inner_firmware: 0,
      groups: [0]
    },
    userAccessesSettings: {
      inner_mac: "string",
      outer_mac: "string",
      is_active: true,
      name: "string",
      type: "door",
      comment: "string",
      connected: true,
      inner_receiver_enabled: true,
      hands_free: true,
      trigger_level: 0,
      reader_accept_bytes: 0,
      lock_connection_type: 0,
      lock_door_opened_time: 0,
      ble: 0,
      wiegand1: 0,
      wiegand2: 0,
      rs1: 0,
      rs2: 0,
      network_status: "On",
      network_ssid: "string",
      network_password: "string",
      object: 0,
      outer_firmware: 0,
      inner_firmware: 0,
      groups: [0]
    }
  },
  userControllersList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  gpioConfigSettings: [
    {
      number: 0,
      logic: null,
      level_of_one: null,
      signal_type: null
    },
    {
      number: 1,
      logic: null,
      level_of_one: null,
      signal_type: null
    },
    {
      number: 2,
      logic: null,
      level_of_one: null,
      signal_type: null
    },
    {
      number: 3,
      logic: null,
      level_of_one: null,
      signal_type: null
    },
    {
      number: 4,
      logic: null,
      level_of_one: null,
      signal_type: null
    },
    {
      number: 5,
      logic: null,
      level_of_one: null,
      signal_type: null
    },
    {
      number: 6,
      logic: null,
      level_of_one: null,
      signal_type: null
    }
    // {
    //   number: 7,
    //   logic: null,
    //   level_of_one: null,
    //   signal_type: null
    // }
  ]
};
const getters = {
  getControllerInfo: state => state.controllerInfo,
  getControllerEditForm: state => state.controllerEditForm,
  getControllerCreateForm: state => state.controllerCreateForm,
  getControllerInfoById: state => state.controllerSettings.controllerInfoById,
  getControllerInfoHashById: state =>
    state.controllerSettings.controllerInfoHashById,
  getPageControllersList: state => state.pageControllersList,
  getDealerControllersList: state => state.dealerControllersList,
  getBluetoothSettings: state => state.controllerSettings.bluetoothettings,
  getEternalInterfacesSettings: state =>
    state.controllerSettings.externalInterfacesSettings,
  getGpioConfigSettings: state => state.controllerSettings.gpioConfigSettings,
  getGpioSettings: state => state.gpioConfigSettings,
  getLockConfigSettings: state => state.controllerSettings.lockConfigSettings,
  getNetworkSettings: state => state.controllerSettings.networkSettings,
  getTimeSettings: state => state.controllerSettings.timeSettings,
  getCardAccessesSettings: state =>
    state.controllerSettings.cardAccessesSettings,
  getUserAccessesSettings: state =>
    state.controllerSettings.userAccessesSettings,
  getOrganizationControllersList: state => state.organizationControllers,
  getObjectOrganizationControllersList: state =>
    state.objectOrganizationControllersList,
  getUserControllersList: state => state.userControllersList
};

const actions = {
  async downloadOrganizationControllersList(context, header) {
    const { query, id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getControllersRequest({
        query: { ...query, limit: 5000, offset: null, object__organization: id }
      })
        .then(response => {
          csvExport(response.data, header, "Контроллеры");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadServiceOrganizationControllersList(context, header) {
    const { query, id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getControllersRequest({
        query: {
          ...query,
          limit: 5000,
          offset: null,
          object__service_organization: id
        }
      })
        .then(response => {
          csvExport(response.data, header, "Контроллеры");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadObjectControllersList(context, header) {
    const { query, id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getControllersRequest({
        query: { ...query, limit: 5000, offset: null, object: id }
      })
        .then(response => {
          csvExport(response.data, header, "Контроллеры");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async downloadPageControllersList(context, header) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getControllersRequest({ query: { ...query, limit: 5000, offset: null } })
        .then(response => {
          csvExport(response.data, header, "Контроллеры");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
    // return downloadControllersListRequest({ query });
  },

  downloadReportsControllerList(context) {
    const { query } = context.getters.getPageQuery;

    return new Promise((resolve, reject) => {
      getControllerRequestReport({
        query: { ...query, offset: null },
        data: {
          ...query
        }
      })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async fetchPageControllersList(context) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getControllersRequest({ query: { ordering, ...query } })
        .then(response => {
          context.commit("setControllersList", {
            listName: "pageControllersList",
            controllers: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadDealerControllersList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getControllersRequest({
        query: {
          ...query,
          limit: 5000,
          offset: null,
          object__organization__diller: id
        }
      })
        .then(response => {
          csvExport(response.data, header, "Контроллеры");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchObjectOrganizationControllersList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getControllersRequest({
        query: { ...query, ordering, object: id }
      })
        .then(response => {
          context.commit("setObjectOrganizationControllersList", {
            listName: "objectOrganizationControllersList",
            controllersList: response.data
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchDealerControllersList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getControllersRequest({
        query: {
          ...query,
          object__organization__diller: id,
          ordering
        }
      })
        .then(response => {
          context.commit("setControllersList", {
            listName: "dealerControllersList",
            controllers: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchOrganizationControllersList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getControllersRequest({
        query: {
          ...query,
          object__organization: id,
          ordering
        }
      })
        .then(response => {
          context.commit("setControllersList", {
            listName: "organizationControllers",
            controllers: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchServiceOrganizationControllersList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getControllersRequest({
        query: {
          ...query,
          ordering,
          object__service_organization: id
        }
      })
        .then(response => {
          context.commit("setControllersList", {
            listName: "organizationControllers",
            controllers: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async clearControllersPage(context) {
    context.commit("clearControllersList", {
      listName: "pageControllersList"
    });
    return Promise.resolve();
  },
  async getControllerSettings(context) {
    const id = context.getters.getPageQuery.id;
    return new Promise((resolve, reject) => {
      getControllerByIdRequest({ id })
        .then(response => {
          context.commit("setControllerSettings", {
            listName: "controllerInfoById",
            listValue: response.data
          });
          let { gpio_configs } = response.data;
          if (gpio_configs && gpio_configs.length) {
            gpio_configs.map(item => {
              context.commit("SET_GPIO_DATA", {
                value: item,
                number: item.number
              });
            });
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async getControllerHash(context) {
    const id = context.getters.getPageQuery.id;
    return new Promise((resolve, reject) => {
      getControllerHashByIdRequest({ id })
        .then(response => {
          if (response.data.app_ttl == null) {
            response.data.app_ttl = 0;
          }
          if (response.data.card_ttl == null) {
            response.data.card_ttl = 0;
          }

          context.commit("setControllerSettings", {
            listName: "controllerInfoHashById",
            listValue: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async SentBleSettings(context) {
    const id = context.getters.getPageQuery.id;
    const bluetoothSettings = context.getters.getBluetoothSettings;
    return new Promise((resolve, reject) => {
      setBleConfigRequest({ id: id, data: bluetoothSettings }).catch(error => {
        reject(error);
      });
    });
  },
  async setExternalInterfaces(context) {
    const id = context.getters.getPageQuery.id;
    const externalInterfacesSettings =
      context.getters.getEternalInterfacesSettings;
    return new Promise((resolve, reject) => {
      setExternalInterfacesRequest({
        id: id,
        data: externalInterfacesSettings
      }).catch(error => {
        reject(error);
      });
    });
  },
  async setGpioConfig(context) {
    const id = context.getters.getPageQuery.id;
    const gpioConfigSettings = context.getters.getGpioConfigSettings;
    return new Promise((resolve, reject) => {
      setGpioConfigRequest({ id: id, data: gpioConfigSettings }).catch(
        error => {
          reject(error);
        }
      );
    });
  },
  async setLockConfig(context) {
    const id = context.getters.getPageQuery.id;
    const lockConfigSettings = context.getters.getLockConfigSettings;
    return new Promise((resolve, reject) => {
      setLockConfigRequest({ id: id, data: lockConfigSettings }).catch(
        error => {
          reject(error);
        }
      );
    });
  },
  async setNetworkConfig(context) {
    const id = context.getters.getPageQuery.id;
    const networkSettings = context.getters.getNetworkSettings;
    return new Promise((resolve, reject) => {
      setNetworkConfigRequest({ id: id, data: networkSettings }).catch(
        error => {
          reject(error);
        }
      );
    });
  },
  async setTime(context) {
    const id = context.getters.getPageQuery.id;
    const timeSettings = context.getters.getTimeSettings;
    return new Promise((resolve, reject) => {
      setTimeRequest({ id: id, data: timeSettings }).catch(error => {
        reject(error);
      });
    });
  },
  async fetchControllersInfo(context) {
    const { id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getControllerByIdRequest({ id })
        .then(response => {
          context.commit("setControllerInfo", {
            infoName: "controllerInfo",
            results: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async uploadCardAccesses(context) {
    const id = context.getters.getPageQuery.id;
    // const cardAccesses = context.getters.getCardAccessesSettings;
    const cardAccesses = {};
    return new Promise((resolve, reject) => {
      uploadCardAccessesRequest({ id: id, data: cardAccesses }).catch(error => {
        reject(error);
      });
    });
  },
  async uploadUserAccesses(context) {
    const id = context.getters.getPageQuery.id;
    // const userAccesses = context.getters.getUserAccessesSettings;
    const userAccesses = {};
    return new Promise((resolve, reject) => {
      uploadUserAccessesRequest({ id: id, data: userAccesses }).catch(error => {
        reject(error);
      });
    });
  },

  async createController(context) {
    const {
      name,
      type,
      object,
      addToAllGroups
    } = context.getters.getControllerCreateForm;

    const userGroups = (await getAccessGroupsRequest({ query: { object } }))
      .data.results;

    let groups;

    if (addToAllGroups) {
      groups = userGroups.map(group => group.id); //во все группы
    } else {
      groups = userGroups // в группы с ролью 120
        .filter(group => group.role === 120)
        .map(group => group.id);
    }

    let data = {
      name,
      type,
      object,
      groups,
      wiegand1: 2,
      wiegand2: 1
    };

    const createResult = createControllerRequest({ data });

    createResult.then(result => {
      router.replace({ path: `controllers/${result.data.id}` }).finally(() => {
        context.commit("clearCreateControllerForm");
      });
    });
    return createResult;
  },

  async prepareEditController(context, { id }) {
    // const controllerInfo = context.getters.getControllerInfo.results;
    //
    const controllerInfo = (await getControllerByIdRequest({ id })).data;
    context.commit("prepareEditController", controllerInfo);
  },

  async editController(context, { id }) {
    const editForm = context.getters.getControllerEditForm;
    let editedData = {};
    for (let key in editForm) {
      if (editForm[key].isEdit) {
        editedData[key] = editForm[key].value;
      }
    }
    const editProm = patchControllerByIdRequest({ id, data: editedData });
    editProm.then(() => {
      router.go(-1);
    });
    return editProm;
  },

  async deleteController(context, { id }) {
    router.go(-1);
    return deleteControllerByIdRequest({ id });
  },
  async deletePermanentlyController(context, { id }) {
    router.go(-1);
    return deletePermanentControllerByIdRequest({ id });
  },
  async banController(context, { id }) {
    const prom = patchControllerByIdRequest({ id, data: { is_active: false } });
    prom.finally(() => {
      // router.go(-1);
      context.dispatch("fetchControllersInfo");
    });
    return prom;
  },
  async unBlockController(context, { id }) {
    const prom = patchControllerByIdRequest({ id, data: { is_active: true } });
    prom.finally(() => {
      // router.go(-1);
      context.dispatch("fetchControllersInfo");
    });
    return prom;
  },

  async fetchUserControllersList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getControllersRequest({
        query: { ...query, ordering, user: id }
      })
        .then(response => {
          context.commit("setControllersList", {
            listName: "userControllersList",
            controllers: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchStaffControllersList(context) {
    const { query } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getControllersRequest({
        query: {
          ...query,
          ordering
        }
      })
        .then(response => {
          context.commit("setControllersList", {
            listName: "userControllersList",
            controllers: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async restoreController(context, { id, data }) {
    return new Promise((resolve, reject) => {
      restoreControllerByIdRequest({ id, data })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async wsControllerConnection(context) {
    const { id, object } = context.getters.getControllerInfoById;
    const host = process.env.VUE_APP_HOST;
    const ws = process.env.NODE_ENV == "development" ? "ws" : "wss";
    const url = `${ws}://${host}/ws/objects/${object}/controllers/${id}/status`;
    return new Promise((resolve, reject) => {
      wsControllerConnection({ url })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  clearCreateControllerForm(state) {
    state.controllerCreateForm = {
      object: null,
      name: "",
      city: null,
      type: "",
      organization: ""
    };
  },
  prepareEditController(state, prepareObj) {
    Object.keys(state.controllerEditForm).forEach(key => {
      state.controllerEditForm[key].value = prepareObj[key];
      state.controllerEditForm[key].isEdit = false;
    });
  },
  changeEditControllerValue(state, { fieldName, value }) {
    state.controllerEditForm[fieldName].value = value;
    state.controllerEditForm[fieldName].isEdit = true;
  },
  setControllerInfo(state, { infoName, results }) {
    state[infoName].results = results;
  },
  setControllerCreateForm(state, { fieldName, value }) {
    state.controllerCreateForm[fieldName] = value;
  },
  setControllersList(state, { listName, controllers }) {
    state[listName] = controllers;
  },
  setObjectOrganizationControllersList(state, { listName, controllersList }) {
    state[listName] = controllersList;
  },
  clearControllersList(state, { listName }) {
    state[listName].results = [];
  },
  setControllerSettings(state, { listName, listValue }) {
    state.controllerSettings[listName] = listValue;
  },
  CLEAR_CONTROLLER_SETTINGS(state) {
    state.controllerSettings.controllerInfoById = {};
  },
  CLEAR_CONTROLLERHASH_SETTINGS(state) {
    state.controllerSettings.controllerInfoHashById = {};
  },
  SET_GPIO_DATA(state, { value, number }) {
    state.gpioConfigSettings[number] = value;
  },
  CLEAR_GPIO_DATA(state) {
    state.gpioConfigSettings = state.gpioConfigSettings.map(function(elem) {
      return {
        ...elem,
        logic: null,
        level_of_one: null,
        signal_type: null
      };
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
